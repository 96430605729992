import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../../utils/RDStationConnection"

import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"

import SectionOne from "../../components/Solutions/Corporate/SectionOne/SectionOne"
import SectionTwo from "../../components/Solutions/Corporate/SectionTwo/SectionTwo"
import SectionThree from "../../components/Solutions/Corporate/SectionThree/SectionThree"
import SectionFour from "../../components/Solutions/Corporate/SectionFour/SectionFour"
// import SectionFive from "../components/Solutions/Fabtech/SectionFive/SectionFive"

export default function Fabcorporate() {
  useEffect(() => {
    RDStationConection.init()
    document.getElementById("label").style.backgroundColor = "#f7ca45"
  })
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAB corporate | FABWORK</title>
        <link rel="canonical" href="https://fab.work/fabcorporate/" />
      </Helmet>
      <Header />
      {/*<div style={{ display: "none" }}></div>*/}
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      {/* <SectionFive /> */}
      <Footer />
    </div>
  )
}
