import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionThree.scss"
import iecei from "../../../../images/mobile/solutions/corporate/iecei.svg"

const SectionThree = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  return (
    <div id="fabcorporate-section-three">
      <div id="content">
        <div data-aos="fade-up" className="section-three-text">
          <h1 className="text-h1">Metodologia IECEI da FABWORK</h1>
        </div>

        <div className="section-three-image">
          <div className="image">
            <img src={iecei} alt="Metodologia IECEI da FABWORK" />
          </div>
          <div className="center">
            <div className="block-one">
              <div className="text01">
                <h1 data-aos="fade-right">Inspirar</h1>
                <p data-aos="fade-right" data-aos-delay="200">
                  Apresentação de casos de sucesso reais e práticos.
                </p>
              </div>

              <div className="text02">
                <h1 data-aos="fade-right" data-aos-delay="100">
                  Conectar
                </h1>
                <p data-aos="fade-right" data-aos-delay="200">
                  Estimulação de atividades em equipes, dinâmicas e integração
                  entre os participantes e suas realidades.
                </p>
              </div>
            </div>

            <div className="block-two">
              <div className="text01">
                <h1 data-aos="fade-left-p">Engajar</h1>
                <p data-aos="fade-left-p" data-aos-delay="200">
                  Aprendizagem baseada em problemas e sala de aula invertida.
                </p>
              </div>

              <div className="text02">
                <h1 data-aos="fade-left-p" data-aos-delay="100">
                  Empoderar
                </h1>
                <p data-aos="fade-left-p" data-aos-delay="200">
                  Ensino de ferramentas e técnicas que os permitam trazer suas
                  próprias propostas de solução.
                </p>
              </div>
            </div>
          </div>

          <div className="block-three">
            <div>
              <h1 data-aos="fade-up">Impactar</h1>
              <p data-aos="fade-up" data-aos-delay="200">
                Direcionamento para aplicação do conteúdo no dia a dia,
                impactando e transformando suas realidades.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionThree
