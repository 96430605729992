import React, { useState, useEffect } from "react"
import { FiPlus, FiMinus } from "react-icons/fi"
import PinnedMenu from "../../../../utils/PinnedMenu"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionTwo.scss"
import "../../../../utils/PinnedMenu/styles.scss"

const SectionTwo = () => {
  const dataDriven = {
    data: require("../../../../images/illustrations/fabcorporate/data-driven/data.svg"),
    strategy: require("../../../../images/illustrations/fabcorporate/data-driven/strategy.svg"),
    inovation: require("../../../../images/illustrations/fabcorporate/data-driven/inovation.svg"),
  }

  const next = {
    mindset: require("../../../../images/illustrations/fabcorporate/next/mindset.svg"),
    sinergy: require("../../../../images/illustrations/fabcorporate/next/sinergy.svg"),
    transformation: require("../../../../images/illustrations/fabcorporate/next/transformation.svg"),
  }

  const agile = {
    deliveries: require("../../../../images/illustrations/fabcorporate/data-driven/inovation.svg"),
    colabs: require("../../../../images/illustrations/fabcorporate/agile/colabs.svg"),
    frameworks: require("../../../../images/illustrations/fabcorporate/agile/frameworks.svg"),
  }

  const [expandedDataDriven, setExpandedDataDriven] = useState(false)
  const [expandedNext, setExpandedNext] = useState(false)
  const [expandedAgile, setExpandedAgile] = useState(false)
  const [active, setActive] = useState("data-driven")

  const [fixedDataDriven, setFixedDataDriven] = useState(false)
  const [fixedNext, setFixedNext] = useState(false)
  const [fixedAgile, setFixedAgile] = useState(false)

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  useEffect(() => {
    function fixedMenusInTop() {
      const dataDrivenMenu = new PinnedMenu(
        "data-driven-menu",
        "data-driven-content"
      )
      const nextMenu = new PinnedMenu("next-menu", "next-content")
      const agileMenu = new PinnedMenu("agile-menu", "agile-content")
      const desktopMenu = new PinnedMenu(
        "collapse-and-slide-menu-2",
        "sliders-2"
      )

      if (dataDrivenMenu.shouldItBePinned(expandedDataDriven))
        dataDrivenMenu.pin()
      else dataDrivenMenu.unpin()

      setFixedDataDriven(dataDrivenMenu.shouldItBePinned(expandedDataDriven))

      if (nextMenu.shouldItBePinned(expandedNext)) nextMenu.pin()
      else nextMenu.unpin()

      setFixedNext(nextMenu.shouldItBePinned(expandedNext))

      if (agileMenu.shouldItBePinned(expandedAgile)) agileMenu.pin()
      else agileMenu.unpin()

      setFixedAgile(agileMenu.shouldItBePinned(expandedAgile))

      if (desktopMenu.shouldItBePinned(true, true)) desktopMenu.pin(true)
      else desktopMenu.unpin(true)
    }

    document.addEventListener("scroll", fixedMenusInTop)
    fixedMenusInTop()
  }, [expandedDataDriven, expandedNext, expandedAgile])

  return (
    <div id="fabcorporate-section-two">
      <h1 data-aos="fade-up">Nossos programas</h1>

      <ul id="collapse-and-slide-menu-2" className="collapse-menu">
        <li className="collapse-menu-item">
          <button
            id="data-driven-menu"
            className={`collapse-menu-header
              ${active === "data-driven" ? "active" : ""} ${
              fixedDataDriven ? "fixed-mobile" : ""
            }`}
            onClick={() => {
              setExpandedDataDriven(!expandedDataDriven)
              setActive("data-driven")
            }}
          >
            <h2 className="collapse-menu-title">DATA DRIVEN</h2>
            {!expandedDataDriven ? (
              <FiPlus size={30} color="#888888" />
            ) : (
              <FiMinus size={30} color="#888888" />
            )}
          </button>
          <div
            id="data-driven-content"
            className={`collapse-menu-content ${
              expandedDataDriven ? "expanded" : "contracted"
            }`}
          >
            <p data-aos="fade-up" className="impact-phrase">
              Formamos profissionais capazes de explorar o potencial dos dados
              através da aplicação de ciência de dados com foco em resultados
              estratégicos.
            </p>

            <div className="explanation">
              <h3 data-aos="fade-up">DATA DRIVEN é para organizações que:</h3>
              <ul className="explanation-items">
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="data"
                    src={dataDriven.data}
                    alt="Dois quadros: o da esquerda tem um gráfico e o da direita com 15 circulos dos quais 4 estão preenchidos."
                  />
                  <p>Precisam gerar, armazenar, minerar e explorar dados</p>
                </li>
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="inovation"
                    src={dataDriven.inovation}
                    alt="Dois quadros: o da esquerda tem um foguete e o da direita um gŕafico."
                  />
                  <p>
                    Visualizam formar uma cultura direcionada a dados para
                    inovar em seu segmento
                  </p>
                </li>
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="strategy"
                    src={dataDriven.strategy}
                    alt="Uma lista de itens."
                  />
                  <p>
                    Possuem muitos dados e precisam utilizá-los na estratégia do
                    negócio
                  </p>
                </li>
              </ul>
            </div>
            <a href="/fabcorporate/data-driven/#">
              <button className="btn">saiba mais</button>
            </a>
          </div>
        </li>
        <li className="collapse-menu-item">
          <button
            id="next-menu"
            className={`collapse-menu-header
              ${active === "next" ? "active" : ""}
              ${fixedNext ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedNext(!expandedNext)
              setActive("next")
            }}
          >
            <h2 className="collapse-menu-title">NEXT</h2>
            {!expandedNext ? (
              <FiPlus size={30} color="#888888" />
            ) : (
              <FiMinus size={30} color="#888888" />
            )}
          </button>
          <div
            id="next-content"
            className={`collapse-menu-content ${
              expandedNext ? "expanded" : "contracted"
            }`}
          >
            <p data-aos="fade-up" className="impact-phrase">
              Formamos profissionais capazes de liderar e influenciar o processo
              de transformação digital nos setores que atuam.
            </p>
            <div className="explanation">
              <h3 data-aos="fade-up">NEXT é para organizações que:</h3>
              <ul className="explanation-items">
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="transformation"
                    src={next.transformation}
                    alt="Mão, que sai da tela de um notebook, apontando para um gráfico."
                  />
                  <p>Desejam iniciar a sua transformação digital</p>
                </li>
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="colabs"
                    src={next.mindset}
                    alt="Uma cabeça onde no lugar que fica o cérebro está um circulo reprensentando a cultura do mindset digital."
                  />
                  <p>Precisam reforçar a cultura/mindset digital</p>
                </li>
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="sinergy"
                    src={next.sinergy}
                    alt="Duas cabeças interligadas por dois quadros simbolizado a sinergia entre equipes."
                  />
                  <p>
                    Precisam trazer sinergia entre equipes no contexto da
                    transformação digital
                  </p>
                </li>
              </ul>
            </div>
            <a href="/fabcorporate/next/#">
              <button className="btn">saiba mais</button>
            </a>
          </div>
        </li>
        <li className="collapse-menu-item">
          <button
            id="agile-menu"
            className={`collapse-menu-header
              ${active === "agile" ? "active" : ""}
              ${fixedAgile ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedAgile(!expandedAgile)
              setActive("agile")
            }}
          >
            <h2 className="collapse-menu-title">AGILE</h2>
            {!expandedAgile ? (
              <FiPlus size={30} color="#888888" />
            ) : (
              <FiMinus size={30} color="#888888" />
            )}
          </button>
          <div
            id="agile-content"
            className={`collapse-menu-content ${
              expandedAgile ? "expanded" : "contracted"
            }`}
          >
            <p data-aos="fade-up" className="impact-phrase">
              Formamos profissionais capazes de gerir projetos e equipes
              utilizando frameworks ágeis, principalmente o SCRUM.
            </p>
            <div className="explanation">
              <h3 data-aos="fade-up">AGILE é para organizações que:</h3>
              <ul className="explanation-items">
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="deliveries"
                    src={agile.deliveries}
                    alt="Dois quadros: o da esquerda tem um foguete e o da direita um gŕafico."
                  />
                  <p>
                    Desejam adaptar seu modelo de gestão de projetos e entregas
                    à nova realidade ágil
                  </p>
                </li>
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="colabs"
                    src={agile.colabs}
                    alt="Dois quadros, onde parte de um está sobre o outro e em cada um contém um indivíduo."
                  />
                  <p>
                    Buscam maior sinergia entre equipes para aprimorar
                    eficiência de entregas
                  </p>
                </li>
                <li data-aos="fade-right" className="explanation-item">
                  <img
                    id="frameworks"
                    src={agile.frameworks}
                    alt="Um cronômetro e cinco checkbox, onde três estão marcados, representando umagestão ágil."
                  />
                  <p>
                    Precisam formar profissionais capazes de executar e
                    multiplicar a execução de projetos ágeis na empresa
                  </p>
                </li>
              </ul>
            </div>
            <a href="/fabcorporate/agile/#">
              <button className="btn">saiba mais</button>
            </a>
          </div>
        </li>
      </ul>

      <ul id="sliders-2" className="slider-menu">
        <li
          className={`slider-menu-page ${
            active === "data-driven" ? "active" : ""
          }`}
        >
          <p data-aos="fade-up" className="impact-phrase">
            Formamos profissionais capazes de explorar o potencial dos dados
            através da aplicação de ciência de dados com foco em resultados
            estratégicos.
          </p>
          <div className="explanation">
            <h3 data-aos="fade-up">DATA DRIVEN é para organizações que:</h3>
            <ul className="explanation-items">
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="data" src={dataDriven.data} alt="" />
                </div>
                <p>Precisam gerar, armazenar, minerar e explorar dados</p>
              </li>
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="inovation" src={dataDriven.inovation} alt="" />
                </div>
                <p>
                  Visualizam formar uma cultura direcionada a dados para inovar
                  em seu segmento
                </p>
              </li>
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="strategy" src={dataDriven.strategy} alt="" />
                </div>
                <p>
                  Possuem muitos dados e precisam utilizá-los na estratégia do
                  negócio
                </p>
              </li>
            </ul>
          </div>
          <a href="/fabcorporate/data-driven/#">
            <button className="btn">saiba mais</button>
          </a>
        </li>
        <li className={`slider-menu-page ${active === "next" ? "active" : ""}`}>
          <p data-aos="fade-up" className="impact-phrase">
            Formamos profissionais capazes de liderar e influenciar o processo
            de transformação digital nos setores que atuam.
          </p>
          <div className="explanation">
            <h3 data-aos="fade-up">NEXT é para organizações que:</h3>
            <ul className="explanation-items">
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="transformation" src={next.transformation} alt="" />
                </div>
                <p>Desejam iniciar a sua transformação digital</p>
              </li>
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="mindset" src={next.mindset} alt="" />
                </div>
                <p>Precisam reforçar a cultura/mindset digital</p>
              </li>
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="sinergy" src={next.sinergy} alt="" />
                </div>
                <p>
                  Precisam trazer sinergia entre equipes no contexto da
                  transformação digital
                </p>
              </li>
            </ul>
          </div>
          <a href="/fabcorporate/next/#">
            <button className="btn">saiba mais</button>
          </a>
        </li>
        <li
          className={`slider-menu-page ${active === "agile" ? "active" : ""}`}
        >
          <p data-aos="fade-up" className="impact-phrase">
            Formamos profissionais capazes de gerir projetos e equipes
            utilizando frameworks ágeis, principalmente o SCRUM.
          </p>
          <div data-aos="fade-up" className="explanation">
            <h3>AGILE é para organizações que:</h3>
            <ul className="explanation-items">
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="deliveries" src={agile.deliveries} alt="" />
                </div>
                <p>
                  Desejam agilizar e ao mesmo tempo manter a qualidade das suas
                  entregas
                </p>
              </li>
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="colabs" src={agile.colabs} alt="" />
                </div>
                <p>
                  Precisam estabelecer uma sinergia entre colaboradores de
                  diversas áreas
                </p>
              </li>
              <li data-aos="fade-right" className="explanation-item">
                <div className="image-container">
                  <img id="frameworks" src={agile.frameworks} alt="" />
                </div>
                <p>
                  Desejam gestores capazes de executar e repassar frameworks
                  ágeis
                </p>
              </li>
            </ul>
          </div>
          <a href="/fabcorporate/agile/#">
            <button className="btn">saiba mais</button>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SectionTwo
