import React from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionOne.scss"

import corporate from "../../../../images/mobile/solutions/corporate/illustration.svg"
import seta from "../../../../images/mobile/solutions/arrow-desk.svg"
import arrow_header from "../../../../images/shapes/arrow-header.svg"

const SectionOne = () => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })
  return (
    <div id="fabcorporate-section-one">
      <div id="content">
        <div className="section-one-text">
          <h1 data-aos="fade-right" data-aos-delay="100" className="title">
            FAB corporate
          </h1>
          <p data-aos="fade-right" data-aos-delay="500">
            Otimizamos a performance da sua empresa através de programas
            customizados de capacitação e mentoria aplicada.
          </p>
        </div>
        <div className="section-one-image">
          <img
            data-aos="fade-in"
            src={corporate}
            alt="Um indivíduo sobe uma escada indo em direção a um gráfico. Além disso, acima do individuo tem uma pessoa com cabelos compridos. Ao redor uma mão segurando documentos típicos de uma corporação."
          />
          <div className="section-one-member">
            <img src={seta} alt="arrow" className="arrow bounce-infinite" />
          </div>
        </div>
      </div>

      <div className="section-one-button">
        <form className="form-inline">
          <a href="/contact/#form" style={{ textDecoration: "none" }}>
            <button
              className="btn"
              type="button"
              onClick={() => {
                window.open()
              }}
            >
              Seja um cliente
              <img
                id="arrow_header"
                src={arrow_header}
                alt="Mais conteúdo abaixo"
              />
            </button>
          </a>
        </form>
      </div>
    </div>
  )
}

export default SectionOne
