import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionFour.scss"
import SliderOne from "../../Fabtech/Slider/SliderOne"

const SectionFour = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  return (
    <div id="fabcorporate-section-four">
      <div id="content" data-aos="fade-up">
        <SliderOne />
      </div>
    </div>
  )
}

export default SectionFour
