import React from "react"
import Slider from "react-slick"
import "./SliderOne.scss"

// import vitrium from "../../../../images/enterprises-grayscale/vitrium.svg"
import carrefour from "../../../../images/enterprises-colorized/carrefour.svg"
import gerdau from "../../../../images/enterprises-colorized/gerdau.svg"

const SliderOne = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "slick-dots",
  }
  return (
    <div id="testmonials-slider-1">
      <h1 className="text-h1">Depoimentos:</h1>
      <Slider {...settings}>
        <div className="section-four-text">
          <p className="text-p">
            "Entregamos um grande desafio técnico e de negócio, envolvendo IA
            para a FABWORK e ficamos muito felizes com o resultado. Este
            resultado foi obtido com grande comprometimento e expertise
            técnica."
          </p>
          <h2 className="text-h2">
            Jozias Rolim <br />
            <span style={{ fontWeight: 700 }}>Gerente de Data & Analytics</span>
          </h2>
          <div className="slider-one-image">
            <img src={carrefour} alt="carrefour" />
          </div>
        </div>
        <div className="section-four-text">
          <p className="text-p lg">
            "Trabalhar com o time Fabwork, foi uma grande experiência.
            Construímos um programa de dados para não cientistas, que causou
            alto impacto nos participantes e áreas de negócios envolvidas, além
            disso, evoluímos juntos na jornada para estabelecer a cultura data
            driven aqui na Gerdau de uma forma muito fluida e divertida. Nessa
            parceria, nunca faltou comprometimento com nosso propósito e
            colaboração!"
          </p>
          <h2 className="text-h2">
            Sandrine Moura <br />
            <span style={{ fontWeight: 700 }}>
              Líder de Change Management {"&"} Digital
            </span>
          </h2>
          <div className="slider-one-image lg">
            <img src={gerdau} alt="carrefour" />
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default SliderOne
